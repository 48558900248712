.yellow-btn {
    background-color: #EAC44B;
    height: 5.1rem;
    padding: 0 2.4rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    color: #0F0F0F;
    font-weight: 700;
    border: none;
    transition: .5s;
    cursor: pointer;
    text-decoration: none;
		@include respond(w600) {
			background-color: #EAC44B;
			height: 40px;
			padding: 0 32px;
			border-radius: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: "Montserrat", sans-serif;
			font-size: 12px;
	
		}

    &:hover {
        background-color: darken(#EAC44B, 10%);
    }
}

.yellow-outline-btn {
    background-color: rgba(#EAC44B, 0);
    height: 4.8rem;
    padding: 0 2.6rem;
    border-radius: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    color: #EAC44B;
    font-weight: 600;
    border: .1rem solid #EAC44B;
    transition: .5s;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: rgba(#EAC44B, .25);
    }
}

.d-flex {
    display: flex;
}

.flex-grow-1 {
    flex-grow: 1;
}

.align-items-center {
    align-items: center;
}