@charset "UTF-8";
/*
0 - 600px:     Phone
600 - 900px:   Tablet Potrait
900 - 1200px:  Tablet Landscape
[1200 - 1800]  is where our normal style applies
1800px + :     Big desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}
@media (max-width: 90em) {
  html {
    font-size: 56%;
  }
}
@media (max-width: 75em) {
  html {
    font-size: 45%;
  }
}
@media (max-width: 48em) {
  html {
    font-size: 37%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 37.5%;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #ffffff;
  background-color: #0F0F0F;
  line-height: normal;
  box-sizing: border-box;
  overflow-x: hidden;
}

.yellow-btn {
  background-color: #EAC44B;
  height: 5.1rem;
  padding: 0 2.4rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  color: #0F0F0F;
  font-weight: 700;
  border: none;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 37.5em) {
  .yellow-btn {
    background-color: #EAC44B;
    height: 40px;
    padding: 0 32px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
  }
}
.yellow-btn:hover {
  background-color: #e5b51d;
}

.yellow-outline-btn {
  background-color: rgba(234, 196, 75, 0);
  height: 4.8rem;
  padding: 0 2.6rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  color: #EAC44B;
  font-weight: 600;
  border: 0.1rem solid #EAC44B;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
}
.yellow-outline-btn:hover {
  background-color: rgba(234, 196, 75, 0.25);
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-center {
  align-items: center;
}

.topButton {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: #eac44a;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  z-index: 999;
  background-image: url(./../../public/images/top.png);
  background-size: cover;
}

.container {
  width: 100%;
  background-color: #0F0F0F;
  position: relative;
  overflow: hidden;
  max-width: 2025px;
  margin: auto;
}
.main-nav {
  width: 100%;
  position: absolute;
  top: 5rem;
  z-index: 200;
}
@media (max-width: 37.5em) {
  .main-nav {
    top: 25px;
  }
}
.main-nav__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 128rem;
  height: 10rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .main-nav__container {
    padding: 0 4rem;
  }
}
.main-nav__logo {
  width: 236px;
  height: auto;
}
@media (max-width: 75em) {
  .main-nav__logo {
    width: 180px;
  }
}
@media (max-width: 37.5em) {
  .main-nav__logo {
    width: 124px;
  }
}
.main-nav__menu {
  background-color: #181818;
  position: fixed;
  top: 3.6rem;
  right: 4.6rem;
  left: 4.6rem;
  z-index: 250;
  padding: 2.8rem 2.6rem;
  border-radius: 3.8rem;
}
@media (max-width: 37.5em) {
  .main-nav__menu {
    top: 2rem;
    right: 2rem;
    left: 2rem;
  }
}
.main-nav__menu--header {
  display: flex;
  align-items: center;
}
.main-nav__menu--header .menu-logo {
  width: 60px;
}
.main-nav__menu--header .menu-title {
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 0 5.2rem;
  flex-grow: 1;
}
.main-nav__menu--header .menu-email {
  font-family: "Jura", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
  margin-right: 6.7rem;
}
.main-nav__menu--header .menu-email:hover {
  opacity: 0.6;
}
@media (max-width: 37.5em) {
  .main-nav__menu--header .menu-email {
    margin-right: 0;
  }
}
.main-nav__menu--body {
  display: flex;
  justify-content: space-between;
  padding: 4rem 2rem 3rem 1.8rem;
}
@media (max-width: 37.5em) {
  .main-nav__menu--body {
    flex-direction: column;
  }
}
.main-nav__menu--body .item .item-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.2rem;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0.9rem;
}
@media (max-width: 37.5em) {
  .main-nav__menu--body .item .item-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 6px;
  }
}
.main-nav__menu--body .item .list {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.5rem;
}
@media (max-width: 37.5em) {
  .main-nav__menu--body .item .list {
    margin-bottom: 30px;
  }
}
.main-nav__menu--body .item .list--item {
  font-family: "Jura", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.9rem;
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
}
@media (max-width: 37.5em) {
  .main-nav__menu--body .item .list--item {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
}
.main-nav__menu--body .item .list--item:hover {
  opacity: 0.6;
}
.main-nav__menu--body .item .list--item + .list--item {
  margin-top: 0.8rem;
}
.main-nav__menu--body .item .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.44rem;
  column-gap: 1.8rem;
}
.main-nav__menu--body .item .grid .btn {
  font-family: "Jura", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #000000;
  background-color: #EAC44B;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  border-radius: 0.5rem;
  border: none;
  margin: 0;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
}
.main-nav__menu--body .item .grid .btn:hover {
  background-color: #e5b51d;
}
.main-nav__menu-bg {
  background-color: rgba(0, 0, 0, 0.36);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 245;
}
.main-nav__icon-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
.main-nav__icon-btn img {
  width: 120px;
  height: auto;
}
@media (max-width: 37.5em) {
  .main-nav__icon-btn img {
    width: 100px;
  }
}
.main-nav__icon-btn:hover {
  opacity: 0.8;
}
.main-nav__btn {
  background: none;
  border: 0.1rem solid #EAC44B;
  border-radius: 1.2rem 0 1.2rem 0;
  height: 5.6rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #EAC44B;
  padding: 0 3.1rem;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}
.main-nav__btn:hover {
  background-color: rgba(234, 196, 75, 0.25);
}
.main-nav__btn + .main-nav__btn {
  margin-left: 1.6rem;
}
.main-nav__btn.menu {
  padding: 0 1.4rem;
  margin-left: 1.86rem;
}

.header {
  width: 100%;
  height: 90rem;
  background-color: #131313;
  background-image: url(./../../public/images/header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header::before {
  content: "";
  position: absolute;
  right: -5.4rem;
  top: 0;
  width: 51rem;
  height: 89rem;
  z-index: 150;
  background-image: url(./../../public/images/header-bg-1.svg);
  background-repeat: no-repeat;
  background-size: 51rem 89rem;
}
@media (max-width: 37.5em) {
  .header::before {
    display: none;
    right: 0;
    top: auto;
    bottom: 0;
    transform: translateY(0);
  }
}
.header::after {
  content: "";
  position: absolute;
  right: -8.2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 53.6rem;
  height: 56.6rem;
  z-index: 160;
  /*background-image: url(./../../public/images/header-bg-2.png);*/
  background-repeat: no-repeat;
  background-size: 53.6rem 56.6rem;
  opacity: 0.36;
}
@media (max-width: 37.5em) {
  .header::after {
    bottom: 4vh;
    display: block;
    right: -2vh;
    top: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    z-index: -999;
    width: 52vh;
    height: 52vh;
    background-size: 53.6vh 56.6vh;
  }
}
@media (max-width: 37.5em) {
  .header {
    height: 75vh;
    padding: 0 2rem;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}
.header__pic {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 93.2rem;
  z-index: 200;
}
@media (max-width: 37.5em) {
  .header__pic {
    width: 90vw;
    height: auto;
    z-index: -1;
  }
}
.header__menu {
  position: absolute;
  left: 5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 37.5em) {
  .header__menu {
    display: none;
  }
}
.header__icon-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
.header__icon-btn img {
  width: 2.2rem;
  height: auto;
}
.header__icon-btn:hover {
  opacity: 0.8;
}
.header__icon-btn + .header__icon-btn {
  margin-top: 3rem;
}
.header__intro-title {
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 500;
  color: #FFD005;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.2rem;
}
.header__title {
  font-size: 7rem;
  line-height: 11.1rem;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 37.5em) {
  .header__title {
    line-height: 64px;
  }
}
.header__title span {
  color: #44C8F2;
  position: relative;
  z-index: 140;
}
.header__title span::after {
  content: "";
  position: absolute;
  top: 4.4rem;
  left: -1.4rem;
  z-index: 130;
  width: 23.7rem;
  height: 5.4rem;
  background-image: url(./../../public/images/nft-text-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.header__desc {
  font-size: 2.4rem;
  line-height: 4.5rem;
  font-weight: 400;
  color: #529CB3;
  text-align: center;
  max-width: 65rem;
  margin-top: 0;
  margin-bottom: 4.8rem;
}
.header__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.header__btns button {
  width: 22.5rem;
}
.header__btns button + button {
  margin-left: 1.6rem;
}
.header__container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 150;
  align-self: flex-start;
  justify-self: center;
  align-self: flex-start;
  justify-self: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 37.5em) {
  .header__container {
    padding: 0 2rem;
    margin: 16vh 0 0;
  }
}
.header__welcome {
  font-family: "Jura", sans-serif;
  font-size: 2.6rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 5px;
}
@media (max-width: 37.5em) {
  .header__welcome {
    font-size: 16px;
    margin-bottom: -5px;
  }
}
.header__title {
  font-size: 5.9rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1.8rem;
}
@media (max-width: 37.5em) {
  .header__title {
    font-size: 34px;
    margin-bottom: 10px;
    text-align: left;
    line-height: 34px;
  }
}
.header__info {
  font-family: "Jura", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: #ffffff;
  margin-bottom: 4.8rem;
  max-width: 54.3rem;
}
@media (max-width: 37.5em) {
  .header__info {
    margin-bottom: 15px;
    margin-top: 0px;
  }
}
.header.inner {
  height: 70rem;
  line-height: normal;
}
@media (max-width: 37.5em) {
  .header.inner {
    height: 60vh;
  }
}
.header.inner .header__title {
  text-align: left;
}
.header.inner .header__welcome {
  margin-top: 30px;
}
.header.inner .header__menu {
  top: 0;
  bottom: 5rem;
  transform: translateY(0);
}
.header.inner::before {
  right: -47vw;
  bottom: 0;
  width: 99.8rem;
  height: 100%;
  /* background-image: url(http://localhost:3001/static/media/inner-header-bg.96ade02….svg); */
  background-size: cover;
  border-radius: 40px;
}
@media (max-width: 37.5em) {
  .header.inner::before {
    right: -70rem;
  }
}
.header.inner::after {
  display: none;
}

.about {
  width: 100%;
  height: 75rem;
  display: flex;
}
@media (max-width: 37.5em) {
  .about {
    height: 100vh;
  }
}
.about__container {
  display: flex;
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
  align-self: flex-start;
  justify-self: center;
}
@media (max-width: 37.5em) {
  .about__container {
    padding: 5vh 4rem;
    flex-direction: column-reverse;
  }
}
.about__team-pic {
  max-width: 60rem;
  flex-grow: 1;
  flex-basis: 1rem;
}
@media (max-width: 37.5em) {
  .about__team-pic {
    width: 100%;
    margin-top: 30px;
  }
}
.about__info {
  flex-grow: 1;
  flex-basis: 1rem;
  margin-left: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .about__info {
    width: 100%;
    margin-left: 0;
  }
}
.about__info--title {
  font-weight: 500;
  font-size: 14px;
  color: #14C4EB;
  line-height: 2.2rem;
  margin-top: 3.6rem;
  margin-bottom: 1.6rem;
}
.about__info--subtitle {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
}
.about__info--desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 2.4rem;
}

.features {
  width: 100%;
  height: 75rem;
  display: flex;
  position: relative;
  background-color: #131313;
}
@media (max-width: 37.5em) {
  .features {
    height: auto;
    padding-bottom: 45vh;
  }
}
.features__container {
  display: flex;
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
  align-self: flex-start;
  justify-self: center;
}
@media (max-width: 37.5em) {
  .features__container {
    flex-direction: column;
    margin: 0;
    padding: 8vh 4rem;
  }
}
.features::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: auto;
  left: 0;
  width: 38.5rem;
  height: 53.5rem;
  background-color: #EAC44B;
  border-radius: 0 2.2rem 2.2rem 0;
  z-index: 10;
}
@media (max-width: 37.5em) {
  .features::before {
    bottom: 0;
    transform: translateY(0);
    width: 58vw;
    height: 45vh;
  }
}
.features.dark {
  background-color: #0F0F0F;
}
@media (max-width: 37.5em) {
  .features.dark.tokenb::before {
    top: 71vh;
    display: none;
  }
}
.features.right .features__container {
  flex-direction: row-reverse;
}
@media (max-width: 37.5em) {
  .features.right .features__container {
    flex-direction: column;
  }
}
.features.right .features__info {
  margin-right: 5.8rem;
}
.features.right::before {
  left: auto;
  right: 0;
  border-radius: 2.2rem 0 0 2.2rem;
}
@media (max-width: 37.5em) {
  .features.right.tokenb::before {
    top: 72vh;
    display: none;
  }
}
@media (max-width: 37.5em) {
  .features.right.tokenc::before {
    top: 38vh;
    display: none;
  }
}
@media (max-width: 37.5em) {
  .features.right {
    margin-top: 0;
  }
}
.features .features__dao-pic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4.5rem;
  width: 65rem;
  z-index: 20;
}
@media (max-width: 37.5em) {
  .features .features__dao-pic {
    bottom: 0vh;
    /* transform: translateY(0) scale(1.2); */
    height: 45vh;
    right: 5vw;
    width: auto;
    top: 70vh;
  }
}
.features .features__token-pic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 19rem;
  width: 43rem;
  height: 43rem;
  border-radius: 2rem;
  z-index: 20;
}
@media (max-width: 37.5em) {
  .features .features__token-pic {
    bottom: 0;
    transform: translateY(0);
    left: 0;
    width: 90%;
    height: auto;
    margin: 0 5%;
  }
}
.features .features__first-collection-pic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 9.8rem;
  width: 52rem;
  height: 49.2rem;
  z-index: 20;
}
@media (max-width: 37.5em) {
  .features .features__first-collection-pic {
    bottom: 1vh;
    transform: translateY(0);
    left: 0;
    width: 90%;
    height: auto;
    margin: 0 5%;
  }
}
.features .features__mundial-pic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14rem;
  width: 49.6rem;
  height: 47rem;
  z-index: 20;
}
@media (max-width: 37.5em) {
  .features .features__mundial-pic {
    top: auto;
    bottom: 4vh;
    transform: translateY(0);
    width: 90%;
    height: auto;
    margin: 0 5%;
    left: 0;
  }
}
.features .features__your-assets-pic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5.8rem;
  height: 60vh;
  border-radius: 2rem;
  z-index: 20;
}
@media (max-width: 37.5em) {
  .features .features__your-assets-pic {
    top: auto;
    transform: translateY(0);
    bottom: 5vh;
    height: 45vh;
  }
}
.features .features__live-streaming-pic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2.5rem;
  height: 60vh;
  z-index: 20;
}
@media (max-width: 37.5em) {
  .features .features__live-streaming-pic {
    top: auto;
    transform: translateY(0);
    height: 45vh;
    bottom: -3vh;
    left: 0;
  }
}
.features .features__voting-pic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8rem;
  width: auto;
  height: 60vh;
  z-index: 20;
}
@media (max-width: 37.5em) {
  .features .features__voting-pic {
    top: auto;
    bottom: -1vh;
    transform: translateY(0);
    height: 45vh;
    width: auto;
  }
}
.features__bg {
  flex-grow: 1;
  flex-basis: 1rem;
  position: relative;
}
@media (max-width: 37.5em) {
  .features__bg {
    height: 40rem;
  }
}
.features__info {
  flex-grow: 1;
  flex-basis: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .features__info {
    width: 100%;
    margin-left: 0;
  }
}
.features__info--title {
  font-weight: 500;
  font-size: 14px;
  color: #14C4EB;
  line-height: 2.2rem;
  margin-top: 3.6rem;
  margin-bottom: 1.6rem;
}
.features__info--subtitle {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
}
@media (max-width: 37.5em) {
  .features__info--subtitle {
    font-weight: 700;
    font-size: 30px;
    color: #FFFFFF;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
.features__info--desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
@media (max-width: 37.5em) {
  .features__info--desc {
    margin-bottom: 18px;
    font-size: 14px;
  }
}

.schermata-pic-cont .schermata-pic {
  width: 100%;
}
@media (max-width: 37.5em) {
  .schermata-pic-cont .schermata-pic {
    width: auto;
    height: 40rem;
  }
}
@media (max-width: 37.5em) {
  .schermata-pic-cont {
    margin-top: 8rem;
    height: 40rem;
    overflow-y: hidden;
    overflow-x: auto;
  }
}

.governance {
  width: 100%;
  position: relative;
  padding: 6rem 0;
}
.governance__container {
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .governance__container {
    padding: 0 4rem;
    flex-direction: column;
  }
}
.governance__title {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 8rem;
  text-align: center;
}
.governance__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.governance__list::before {
  content: "";
  width: 0.5rem;
  background-color: #FFFFFF;
  border-radius: 0.1rem;
  position: absolute;
  top: 0;
  bottom: 6.4rem;
  left: calc(50% - 0.25rem);
  transform: translateX(-50%);
}
@media (max-width: 37.5em) {
  .governance__list::before {
    right: 0;
    left: auto;
    transform: translateX(0);
  }
}
.governance__list--item {
  display: flex;
  flex-direction: row;
  position: relative;
}
@media (max-width: 37.5em) {
  .governance__list--item {
    flex-direction: column;
  }
}
.governance__list--item::before {
  content: "";
  position: absolute;
  top: 2rem;
  left: calc(50% - 1.1rem);
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.8rem;
  background-color: #FFFFFF;
}
@media (max-width: 37.5em) {
  .governance__list--item::before {
    right: -0.5rem;
    left: auto;
  }
}
.governance__list--item .item-info {
  flex-grow: 1;
  flex-basis: 1rem;
  padding-right: 4.8rem;
}
.governance__list--item .item-info--box {
  background-color: rgba(0, 0, 0, 0.27);
  border-radius: 2.4rem;
  border: 0.1rem solid #444444;
  padding: 3.2rem;
}
.governance__list--item .item-info--box .title {
  font-weight: 600;
  font-size: 14px;
  color: #EAC44B;
  line-height: 1.9rem;
  margin-top: 0;
  margin-bottom: 1.8rem;
}
.governance__list--item .item-info--box .subtitle {
  font-weight: 600;
  font-size: 2.2rem;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 1.4rem;
}
.governance__list--item .item-info--box .detail {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 3.2rem;
  margin-top: 0;
  margin-bottom: 0;
}
.governance__list--item .item-chart {
  flex-grow: 1;
  flex-basis: 1rem;
  padding-left: 4.8rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .governance__list--item .item-chart {
    padding-right: 4.8rem;
    padding-left: 0;
    padding-top: 4.8rem;
  }
}
.governance__list--item .item-chart .chart {
  width: 36vh;
  height: 36vh;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(closest-side, #0f0f0f 77%, transparent 0 99.9%, #000 0), conic-gradient(#eac44a 0 20%, #000 20% 100%);
}
@media (max-width: 37.5em) {
  .governance__list--item .item-chart .chart {
    width: 32vh;
    height: 32vh;
  }
}
.governance__list--item .item-chart .chart .chart-title {
  font-weight: 600;
  font-size: 17px;
  color: #FFFFFF;
  line-height: 2.5rem;
  margin-top: 0;
  margin-bottom: 2rem;
}
.governance__list--item .item-chart .chart .chart-percent {
  font-family: "Jura", sans-serif;
  font-weight: 600;
  font-size: 2.4rem;
  color: #FFFFFF;
  line-height: 2.8rem;
  margin-top: 0;
  margin-bottom: 0;
}
.governance__list--item + .governance__list--item {
  margin-top: 8.5rem;
}
.governance__list--item.right {
  flex-direction: row-reverse;
}
@media (max-width: 37.5em) {
  .governance__list--item.right {
    flex-direction: column;
  }
}
.governance__list--item.right .item-info {
  padding-right: 0;
  padding-left: 4.8rem;
}
@media (max-width: 37.5em) {
  .governance__list--item.right .item-info {
    padding-right: 4.8rem;
    padding-left: 0;
  }
}
.governance__list--item.right .item-chart {
  padding-right: 4.8rem;
  padding-left: 0;
}

.team {
  width: 100%;
  position: relative;
  padding: 2rem 0 11.8rem;
}
.team__container {
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .team__container {
    padding: 0 4rem;
  }
}
.team__title {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 6.5rem;
  text-align: left;
}
.team__nav {
  display: flex;
  position: absolute;
  right: 0;
  bottom: -5rem;
}
@media (max-width: 37.5em) {
  .team__nav {
    right: 4rem;
  }
}
.team__nav--btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  transition: 0.5s;
  cursor: pointer;
}
.team__nav--btn img {
  width: 2.5rem;
  height: 2.5rem;
}
.team__nav--btn:hover {
  opacity: 0.6;
}
.team__nav--btn + .team__nav--btn {
  margin-left: 1.6rem;
}
.team__list {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: 0.5s;
  scroll-behavior: smooth;
}
.team__list--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 1rem;
  min-width: calc(33.3333333333% - 1.4rem);
  background: linear-gradient(102.17deg, rgba(0, 0, 0, 0.64) 0.47%, rgba(22, 22, 22, 0) 121.14%);
  filter: drop-shadow(0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25));
  border-radius: 2.4rem;
  border: 0.1rem solid rgba(234, 196, 75, 0.21);
  padding: 2.7rem 2.3rem;
}
@media (max-width: 37.5em) {
  .team__list--item {
    min-width: 100%;
  }
}
.team__list--item + .team__list--item {
  margin-left: 2.1rem;
}
@media (max-width: 37.5em) {
  .team__list--item + .team__list--item {
    margin-top: 0rem;
    margin-left: 0;
  }
}
.team__list--item .team-pic {
  width: 13.2rem;
  height: 13.2rem;
  border-radius: 7rem;
  object-fit: cover;
  margin-bottom: 2.4rem;
}
.team__list--item .name {
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 8px;
  text-align: center;
}
@media (max-width: 37.5em) {
  .team__list--item .name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
  }
}
.team__list--item .position {
  font-weight: 500;
  font-size: 9px;
  color: #EAC44B;
  margin-top: 0;
  margin-bottom: 8px;
  text-align: center;
}
@media (max-width: 37.5em) {
  .team__list--item .position {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 8px;
  }
}
.team__list--item .desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  flex-grow: 1;
}
@media (max-width: 37.5em) {
  .team__list--item .desc {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  }
}
.team__list--item .yellow-outline-btn {
  width: 100%;
}
@media (max-width: 37.5em) {
  .team__list--item .yellow-outline-btn {
    font-size: 14px;
    height: 50px;
  }
}

.roadmap {
  width: 100%;
  position: relative;
  padding: 2rem 0 11.8rem;
}
.roadmap__container {
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .roadmap__container {
    padding: 0;
  }
}
.roadmap__title {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 6.5rem;
  text-align: left;
}
@media (max-width: 37.5em) {
  .roadmap__title {
    padding: 0 4rem;
  }
}
.roadmap__nav {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
@media (max-width: 37.5em) {
  .roadmap__nav {
    right: 4rem;
    display: none;
  }
}
.roadmap__nav--btn {
  background: none;
  border: none;
  padding: 0;
  margin: 10px;
  transition: 0.5s;
  cursor: pointer;
}
.roadmap__nav--btn img {
  width: 35px;
  height: 35px;
}
@media (max-width: 37.5em) {
  .roadmap__nav--btn img {
    width: 25px;
    height: 25px;
  }
}
.roadmap__nav--btn:hover {
  opacity: 0.6;
}
.roadmap__nav--btn + .team__nav--btn {
  margin-left: 1.6rem;
}
.roadmap__list {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: 0.5s;
  scroll-behavior: smooth;
}
.roadmap__list--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 1rem;
  min-width: calc(33.3333333333% - 1.4rem);
}
@media (max-width: 37.5em) {
  .roadmap__list--item {
    min-width: 100%;
    overflow-y: auto;
  }
}
.roadmap__list--item + .team__list--item {
  margin-left: 2.1rem;
}
@media (max-width: 37.5em) {
  .roadmap__list--item + .team__list--item {
    margin-top: 0rem;
    margin-left: 0;
  }
}
.roadmap__list--item .roadmap-pic {
  height: 64vh;
  left: 28vw;
  position: relative;
}
@media (max-width: 90em) {
  .roadmap__list--item .roadmap-pic {
    left: 16vw;
  }
}
@media (max-width: 75em) {
  .roadmap__list--item .roadmap-pic {
    left: 14vw;
  }
}
@media (max-width: 37.5em) {
  .roadmap__list--item .roadmap-pic {
    height: 48vh;
    position: relative;
    left: 87vw;
  }
}
.roadmap__list--item .name {
  font-weight: 600;
  font-size: 2.4rem;
  color: #FFFFFF;
  line-height: 2.5rem;
  margin-top: 0;
  margin-bottom: 1.2rem;
  text-align: center;
}
@media (max-width: 37.5em) {
  .roadmap__list--item .name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
  }
}
.roadmap__list--item .position {
  font-weight: 500;
  font-size: 1.4rem;
  color: #EAC44B;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 1.2rem;
  text-align: center;
}
@media (max-width: 37.5em) {
  .roadmap__list--item .position {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 8px;
  }
}
.roadmap__list--item .desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: #FFFFFF;
  line-height: 2.7rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
  text-align: center;
  flex-grow: 1;
}
@media (max-width: 37.5em) {
  .roadmap__list--item .desc {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  }
}
.roadmap__list--item .yellow-outline-btn {
  width: 100%;
}
@media (max-width: 37.5em) {
  .roadmap__list--item .yellow-outline-btn {
    font-size: 14px;
    height: 50px;
  }
}

.main-footer {
  background-color: #131313;
  width: 100%;
  position: relative;
  padding: 3.4rem 0;
}
.main-footer__container {
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
}
@media (max-width: 37.5em) {
  .main-footer__container {
    padding: 0 4rem;
  }
}
.main-footer__logo {
  width: 20rem;
  align-self: center;
}
.main-footer__links {
  display: flex;
  padding-top: 9rem;
  padding-bottom: 3.2rem;
  border-bottom: 0.1rem solid #FFFFFF;
}
@media (max-width: 37.5em) {
  .main-footer__links {
    flex-direction: column;
  }
}
.main-footer__links--item {
  flex-grow: 1;
}
.main-footer__links--item .title {
  font-size: 2.4rem;
  line-height: 20px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 1.6rem;
}
.main-footer__links--item .detail {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 2.4rem;
  width: 46rem;
}
.main-footer__links--item .check-item {
  font-size: 14px;
  line-height: 2.2rem;
  font-weight: 600;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 2.4rem;
  padding-left: 3.4rem;
  position: relative;
}
.main-footer__links--item .check-item:last-child {
  margin-bottom: 0;
}
.main-footer__links--item .check-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  background-image: url(./../../public/icons/check-ico.svg);
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
}
.main-footer__links--item .email {
  font-size: 14px;
  line-height: 3.5rem;
  font-weight: 400;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  margin-bottom: 2.4rem;
  padding-left: 3.6rem;
  position: relative;
  text-decoration: none;
  transition: 0.5s;
}
.main-footer__links--item .email:hover {
  opacity: 0.8;
}
.main-footer__links--item .email:last-child {
  margin-bottom: 0;
}
.main-footer__links--item .email::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 2.1rem;
  height: 1.5rem;
  background-image: url(./../../public/icons/email-ico.svg);
  background-repeat: no-repeat;
  background-size: 2.1rem 1.5rem;
}
.main-footer__links--item .documents {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 2.4rem;
  column-gap: 2.4rem;
  padding-top: 0.8rem;
}
.main-footer__links--item .pdf {
  font-size: 14px;
  line-height: 2.2rem;
  font-weight: 400;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  padding-left: 3.6rem;
  position: relative;
  text-decoration: none;
  transition: 0.5s;
}
.main-footer__links--item .pdf:hover {
  opacity: 0.8;
}
.main-footer__links--item .pdf:last-child {
  margin-bottom: 0;
}
.main-footer__links--item .pdf::before {
  content: "";
  position: absolute;
  top: -0.2rem;
  left: 0;
  width: 1.8rem;
  height: 2.4rem;
  background-image: url(./../../public/icons/pdf-ico.svg);
  background-repeat: no-repeat;
  background-size: 1.8rem 2.4rem;
}
@media (max-width: 37.5em) {
  .main-footer__links--item + .main-footer__links--item {
    margin-top: 6rem;
  }
}
.main-footer__social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.4rem;
}
.main-footer__social__icon-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
.main-footer__social__icon-btn img {
  width: 2.55rem;
  height: auto;
}
.main-footer__social__icon-btn:hover {
  opacity: 0.8;
}
.main-footer__social__icon-btn + .main-footer__social__icon-btn {
  margin-left: 1.8rem;
}

.company {
  width: 100%;
  height: 87rem;
  display: flex;
}
.company.gray {
  background-color: #131313;
}
@media (max-width: 37.5em) {
  .company {
    height: auto;
    padding: 8rem 0;
  }
}
.company__container {
  display: flex;
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
  align-self: flex-start;
  justify-self: center;
}
@media (max-width: 37.5em) {
  .company__container {
    padding: 0 4rem;
    flex-direction: column-reverse;
  }
}
.company__pic {
  flex-grow: 1;
  flex-basis: 1rem;
  display: flex;
}
@media (max-width: 37.5em) {
  .company__pic {
    width: 100%;
  }
}
.company__pic .how-it-work-pic {
  width: 100%;
  max-width: 52rem;
}
.company__pic .token-pic {
  width: 100%;
  max-width: 52rem;
}
@media (max-width: 37.5em) {
  .company__pic .token-pic {
    max-width: 90%;
    display: block;
    margin: 35px 5% 0;
  }
}
.company__info {
  flex-grow: 1;
  flex-basis: 1rem;
  margin-left: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .company__info {
    width: 100%;
    margin-left: 0;
  }
}
.company__info--title {
  font-weight: 500;
  font-size: 14px;
  color: #14C4EB;
  line-height: 2.2rem;
  margin-top: 3.6rem;
  margin-bottom: 1.6rem;
}
.company__info--subtitle {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
}
.company__info--desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 2.4rem;
}

.the-company {
  width: 100%;
  height: 74.5rem;
  display: flex;
  background-color: #131313;
}
@media (max-width: 37.5em) {
  .the-company {
    height: auto;
  }
}
.the-company__container {
  display: flex;
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
  align-self: flex-start;
  justify-self: center;
}
@media (max-width: 37.5em) {
  .the-company__container {
    padding: 8rem 4rem;
    flex-direction: column-reverse;
  }
}
.the-company__chart {
  flex-grow: 1;
  flex-basis: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .the-company__chart {
    width: 100%;
  }
}
.the-company__chart .chart {
  width: 36vh;
  height: 36vh;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(closest-side, #0f0f0f 83%, transparent 0 99.9%, #000 0), conic-gradient(#eac44a 0 49%, #fff 49% 80%, #000 80% 100%);
}
@media (max-width: 37.5em) {
  .the-company__chart .chart {
    margin-bottom: 4rem;
  }
}
.the-company__chart .chart .chart-title {
  font-weight: 600;
  font-size: 4.4rem;
  color: #FFFFFF;
  line-height: 4.6rem;
  margin-top: 0;
}
.the-company__chart .chart .chart-percent {
  font-family: "Jura", sans-serif;
  font-weight: 600;
  font-size: 2.4rem;
  color: #FFFFFF;
  line-height: 2.8rem;
  margin-top: 0;
  margin-bottom: 0;
}
.the-company__info {
  flex-grow: 1;
  flex-basis: 1rem;
  margin-right: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 37.5em) {
  .the-company__info {
    width: 100%;
    margin-left: 0;
  }
}
.the-company__info--title {
  font-weight: 500;
  font-size: 14px;
  color: #14C4EB;
  line-height: 2.2rem;
  margin-top: 3.6rem;
  margin-bottom: 1.6rem;
}
.the-company__info--subtitle {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
}
.the-company__info--desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 2.4rem;
}

.video {
  width: 100%;
  padding: 8rem 6.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 37.5em) {
  .video {
    padding: 4rem;
  }
}
@media (max-width: 37.5em) and (max-width: 37.5em) {
  .video {
    padding: 20px;
  }
}
.video__bg {
  width: 100%;
  border-radius: 20px;
}
@media (max-width: 37.5em) {
  .video__bg {
    height: 30vh;
    object-fit: cover;
  }
}
.video__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video__title {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 5px;
}
@media (max-width: 37.5em) {
  .video__title {
    font-size: 30px;
    line-height: 35px;
    margin-top: 0;
    margin-bottom: 5px;
  }
}
.video__desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  color: #FFFFFF;
  line-height: 3rem;
  margin-top: 0;
  margin-bottom: 5.4rem;
}
@media (max-width: 37.5em) {
  .video__desc {
    font-size: 14px;
    margin-bottom: 18px;
    line-height: 15px;
  }
}
.video__btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.5s;
}
.video__btn:hover {
  opacity: 0.6;
}
.video__btn img {
  width: 11.2rem;
}
@media (max-width: 37.5em) {
  .video__btn img {
    width: 65px;
  }
}

.swot-analysis {
  width: 100%;
  padding: 7rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.swot-analysis__container {
  width: 100%;
  max-width: 128rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
}
@media (max-width: 37.5em) {
  .swot-analysis__container {
    padding: 7rem 4rem;
  }
}
.swot-analysis__title {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 7rem;
  text-align: center;
}
.swot-analysis__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 4.7rem;
  column-gap: 4.7rem;
}
@media (max-width: 37.5em) {
  .swot-analysis__list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.swot-analysis__list--item {
  background: linear-gradient(102.17deg, #131313 0.47%, #131313 121.14%);
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  border-radius: 2.4rem;
  border: 0.1rem solid rgba(233, 233, 233, 0.05);
  padding: 3.6rem 3.2rem;
}
.swot-analysis__list--item .title {
  font-weight: 600;
  font-size: 14px;
  color: #EAC44B;
  line-height: 1.9rem;
  margin-top: 0;
  margin-bottom: 2.2rem;
}
.swot-analysis__list--item .desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 0;
}

.check-ranking {
  background-color: #0F0F0F;
  width: 100%;
  height: 94.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.check-ranking::before {
  content: "";
  background-color: #EAC44B;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 45rem;
  border-radius: 0 2.2rem 2.2rem 0;
}
@media (max-width: 37.5em) {
  .check-ranking::before {
    top: 8rem;
    left: 0;
    bottom: 8rem;
    width: 20vw;
  }
}
.check-ranking::after {
  content: "";
  position: absolute;
  left: -36rem;
  top: 50%;
  transform: translateY(-50%);
  width: 65.5rem;
  height: 75.3rem;
  z-index: 15;
  background-image: url(./../../public/images/header-bg-2.png);
  background-repeat: no-repeat;
  background-size: 65.5rem 75.3rem;
  opacity: 0.36;
}
@media (max-width: 37.5em) {
  .check-ranking::after {
    transform: translateY(-50%) scale(0.8);
    display: none;
  }
}
.check-ranking__title {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.6rem;
  position: relative;
  z-index: 20;
}
.check-ranking__desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 20;
}
.check-ranking__pic {
  width: 100%;
  max-width: 100rem;
  position: relative;
  z-index: 20;
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.check-ranking__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 25;
  transform: translateX(-50%) translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.5s;
}
.check-ranking__btn:hover {
  opacity: 0.6;
}
.check-ranking__btn img {
  width: 11.2rem;
}
@media (max-width: 37.5em) {
  .check-ranking__btn img {
    width: 65px;
  }
}

.vip-collection {
  background-color: #131313;
  width: 100%;
  height: 75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-left: 12.4rem;
}
@media (max-width: 37.5em) {
  .vip-collection {
    height: auto;
    padding-top: 8rem;
    padding-bottom: 60rem;
    padding-left: 8rem;
  }
}
.vip-collection::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 5.7rem;
  height: 53.5rem;
  z-index: 10;
  border-radius: 0 2.2rem 2.2rem 0;
}
@media (max-width: 37.5em) {
  .vip-collection::before {
    top: auto;
    transform: translateY(0);
    bottom: 0;
  }
}
.vip-collection::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 56rem;
  height: 64rem;
  z-index: 10;
  background-image: url(./../../public/images/HomeCard.png);
  background-repeat: no-repeat;
  background-size: 56rem 64rem;
}
@media (max-width: 37.5em) {
  .vip-collection::after {
    top: auto;
    transform: translateY(0);
    bottom: 0;
    width: 49rem;
    height: 53.5rem;
    background-size: 49rem 53.5rem;
  }
}
.vip-collection__title {
  font-weight: 700;
  font-size: 5.4rem;
  color: #FFFFFF;
  line-height: 5.8rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
  position: relative;
  z-index: 20;
}
.vip-collection__desc {
  font-family: "Jura", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 3.2rem;
  position: relative;
  z-index: 20;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btn-close {
  position: absolute;
  top: 35px;
  right: 30px;
  background: #000;
  border: 0;
  color: white;
  font-size: 19px;
  border-radius: 100px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .btn-close {
    font-size: 19px;
    border-radius: 100px;
    width: 28px;
    height: 28px;
  }
}

