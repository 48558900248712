$color-primary: #EAC44B;

$color-white: #ffffff;
$color-black: #000000;
$color-grey: #0F0F0F;
$color-blue: #6F5BEB;
$color-orange: #EBB15B;
$color-green: #89EB5B;
$color-pink: #E87A95;

$color-nutraul: #222325;

$border-radius: .8rem;
$border-radius-sm: .5rem;
$border-radius-lg: 1rem;
$border-width: .1rem;