*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
  font-size: 62.5%;

  @include respond(w1440) {
    //font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    font-size: 56%;
  }

  @include respond(w1200) {
    //font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    font-size: 45%;
  }

  @include respond(w768) {
    //font-size: 50%; //1rem = 8px, 8/16 = 50%
    font-size: 37%;
  }

  // @include respond(big-desktop) {
  //   font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
  // }

  @include respond(w600) {
    font-size: 37.5%; //1rem = 6px, 6/16 = 37.5%
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: $color-white;
  background-color: $color-grey;
  line-height: normal;
  box-sizing: border-box;
  overflow-x: hidden;
}