body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:-webkit-any-link{
	text-decoration: none !important;
}

.menubutton {
	background-color: #eac44a !important;
	opacity: 1 !important;
	padding: 8px 20px !important;
	border-radius: 10px !important;
	border: 3px solid #000 !important;
	font-weight: bold !important;
}

.menubutton > img {
	width: 22px !important;
	margin-left: 10px;
	position: relative;
	top: 1px;
}

.menubutton > span {
		top: -2px !important;
    position: relative;
		font-family: "Montserrat", sans-serif;
		font-size: 18px !important;
}


table {
	width: 90% !important;
	max-width: 900px;
	margin: auto;
	display: block;
	font-size: 12px;
	overflow-x: auto;

}

tr {
	width: 100%;
	display: block;
}

td, th{
	min-width: 235px;
	width: 50%;
	border: 0px;
	margin: 0px;
	padding: 10px;
	border-bottom: 2px solid #4c4c4c;
	text-align: left;
}

.porftolioColumnImage{
	min-width: 135px;
}

.porftolioColumnName{
	min-width: 160px;
}

.porftolioColumnDescription{
	min-width: 400px;
}

.porftolioColumnDownload{
	min-width: 100px;
	text-align: center;
}

.portfolioImage{
	width: 100px;
}

a.email {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	margin: 20px auto;
	display: block;
}