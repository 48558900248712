@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "base/base";
@import "components/components";

.topButton {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: #eac44a;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  z-index: 999;
  background-image: url(./../../public/images/top.png);
  background-size: cover;
}


.container {
	width: 100%;
	background-color: #0F0F0F;
	position: relative;
	overflow: hidden;
	max-width: 2025px;
	margin: auto;
	@include respond(w600) {}
}


.main-nav {
    width: 100%;
    position: absolute;
    top: 5rem;
    z-index: 200;
		@include respond(w600) {
			top: 25px;
		}

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 128rem;
        height: 10rem;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 0 4rem;
        }
    }

    &__logo {
        width: 236px;
        height: auto;
				@include respond(w1200) {
					width: 180px;

				}

				@include respond(w600) {
					width: 124px;
				}

    }

    &__menu {
        background-color: #181818;
        position: fixed;
        top: 3.6rem;
        right: 4.6rem;
        left: 4.6rem;
        z-index: 250;
        padding: 2.8rem 2.6rem;
        border-radius: 3.8rem;

        @include respond(w600) {
            top: 2rem;
            right: 2rem;
            left: 2rem;
        }

        &--header {
            display: flex;
            align-items: center;

            .menu-logo {
                width: 60px;
            }

            .menu-title {
                font-size: 22px;
                font-weight: 700;
                color: #ffffff;
                margin: 0 0 0 5.2rem;
                flex-grow: 1;
            }

            .menu-email {
                font-family: "Jura", sans-serif;
                font-size: 2rem;
                font-weight: 600;
                line-height: 2.4rem;
                color: #ffffff;
                text-decoration: none;
                transition: .5s;
                margin-right: 6.7rem;

                &:hover {
                    opacity: .6;
                }

                @include respond(w600) {
                    margin-right: 0;
                }
            }
        }

        &--body {
            display: flex;
            justify-content: space-between;
            padding: 4rem 2rem 3rem 1.8rem;

            @include respond(w600) {
                flex-direction: column;
            }

            .item {
                .item-title {
                    font-size: 3rem;
                    font-weight: 700;
                    line-height: 3.2rem;
                    color: #ffffff;
                    margin-top: 0;
                    margin-bottom: .9rem;
										@include respond(w600) {

										font-size: 18px;
										font-weight: 700;
										line-height: 18px;
										margin-bottom: 6px;
										}
                }

                .list {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 4.5rem;
										@include respond(w600) {
											margin-bottom: 30px;
									}

                    &--item {
                        font-family: "Jura", sans-serif;
                        font-size: 1.6rem;
                        font-weight: 600;
                        line-height: 1.9rem;
                        color: #ffffff;
                        text-decoration: none;
                        transition: .5s;
												@include respond(w600) {
														font-size: 12px;
														font-weight: 600;
														line-height: 12px;
												}
					

                        &:hover {
                            opacity: .6;
                        }

                        &+.list--item {
                            margin-top: .8rem;
                        }
                    }
                }

                .grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    row-gap: 1.44rem;
                    column-gap: 1.8rem;

                    .btn {
											font-family: "Jura", sans-serif;
											font-size: 11px;
											font-weight: bold;
											color: #000000;
											background-color: #EAC44B;
											width: 100%;
											height: 30px;
											display: flex;
											align-items: center;
											padding: 0 1.6rem;
											border-radius: 0.5rem;
											border: none;
											margin: 0;
											text-decoration: none;
											transition: 0.5s;
											cursor: pointer;
											                        &:hover {
                            background-color: darken(#EAC44B, 10%);
                        }
                    }
                }
            }
        }

        @include respond(w600) {}
    }

    &__menu-bg {
        background-color: rgba(0, 0, 0, 0.36);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 245;
    }

    &__icon-btn {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        img {
						width: 120px;
						height: auto;

            @include respond(w600) {
								width: 100px;

            }
        }

        &:hover {
            opacity: .8;
        }
    }

    &__btn {
        background: none;
        border: .1rem solid $color-primary;
        border-radius: 1.2rem 0 1.2rem 0;
        height: 5.6rem;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-primary;
        padding: 0 3.1rem;
        align-items: center;
        justify-content: center;
        transition: .5s;
        cursor: pointer;

        &:hover {
            background-color: rgba($color-primary, .25);
            // color: $color-black;
        }

        &+.main-nav__btn {
            margin-left: 1.6rem;
        }

        &.menu {
            padding: 0 1.4rem;
            margin-left: 1.86rem;
        }
    }
}

.header {
    width: 100%;
    // max-width: 98rem;
    height: 90rem;
    // margin: 8rem auto 0;
    background-color: #131313;
    background-image: url(./../../public/images/header-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
		
    &::before {
        content: '';
        position: absolute;
        right: -5.4rem;
        top: 0;
        width: 51rem;
        height: 89rem;
        z-index: 150;
        background-image: url(./../../public/images/header-bg-1.svg);
        background-repeat: no-repeat;
        background-size: 51rem 89rem;

        @include respond(w600) {
            display: none;
            right: 0;
            top: auto;
            bottom: 0;
            transform: translateY(0);
        }
    }

    &::after {
        content: '';
        position: absolute;
        right: -8.2rem;
        top: 50%;
        transform: translateY(-50%);
        width: 53.6rem;
        height: 56.6rem;
        z-index: 160;
        /*background-image: url(./../../public/images/header-bg-2.png);*/
        background-repeat: no-repeat;
        background-size: 53.6rem 56.6rem;
        opacity: .36;

        @include respond(w600) {
					bottom: 4vh;
					display: block;
					right: -2vh;
					top: auto;
					-webkit-transform: translateY(0);
					transform: translateY(0);
					z-index: -999;
					width: 52vh;
					height: 52vh;
					background-size: 53.6vh 56.6vh;

				}
    }

    @include respond(w1440) {}

    @include respond(w1200) {}

    @include respond(w1024) {}

    @include respond(w768) {}

    @include respond(w600) {
        height: 75vh;
        padding: 0 2rem;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    &__pic {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 93.2rem;
        //height: 74.7rem;
        z-index: 200;

        @include respond(w600) {
            width: 90vw;
            height: auto;
						z-index: -1;
        }
    }

    &__menu {
        position: absolute;
        left: 5rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-end;

        @include respond(w600) {
            display: none;
        }
    }

    &__icon-btn {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        img {
            width: 2.2rem;
            height: auto;
        }

        &:hover {
            opacity: .8;
        }

        &+.header__icon-btn {
            margin-top: 3rem;
        }
    }

    &__intro-title {
        font-size: 2.4rem;
        line-height: 2.9rem;
        font-weight: 500;
        color: #FFD005;
        text-align: center;
        margin-top: 0;
        margin-bottom: 1.2rem;
    }

    &__title {
        font-size: 7rem;
        line-height: 11.1rem;
        font-weight: 700;
        color: #FFFFFF;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;

				@include respond(w600) {
					line-height: 64px;
				}


        span {
            color: #44C8F2;
            position: relative;
            z-index: 140;

            &::after {
                content: '';
                position: absolute;
                top: 4.4rem;
                left: -1.4rem;
                z-index: 130;
                width: 23.7rem;
                height: 5.4rem;
                background-image: url(./../../public/images/nft-text-bg.png);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    &__desc {
        font-size: 2.4rem;
        line-height: 4.5rem;
        font-weight: 400;
        color: #529CB3;
        text-align: center;
        max-width: 65rem;
        margin-top: 0;
        margin-bottom: 4.8rem;
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;

        button {
            width: 22.5rem;
        }

        button+button {
            margin-left: 1.6rem;
        }
    }

    &__container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 150;
        align-self: flex-start;
        justify-self: center;
        align-self: flex-start;
        justify-self: center;
        display: flex;
        flex-direction: column;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 0 2rem;
            margin: 16vh 0 0;
        }
    }

    &__welcome {
        font-family: 'Jura', sans-serif;
        font-size: 2.6rem;
        font-weight: 700;
        color: $color-white;
        margin-bottom: 5px;
				@include respond(w600) {
					font-size: 16px;
					margin-bottom: -5px;
				}
    }

    &__title {
        // font-family: 'Jura', sans-serif;
        font-size: 5.9rem;
        font-weight: 700;
        color: $color-white;
        margin-bottom: 1.8rem;
				@include respond(w600) {
					font-size: 34px;
					margin-bottom: 10px;
					text-align: left;
					line-height: 34px;
				}

				
    }

    &__info {
        font-family: 'Jura', sans-serif;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.4rem;
        color: $color-white;
        margin-bottom: 4.8rem;
        max-width: 54.3rem;

				@include respond(w600) {
					margin-bottom: 15px;
					margin-top: 0px;
				}

    }

    &.inner {
        height: 70rem;
				line-height: normal;
				@include respond(w600) {
					height: 60vh;
				}

        .header__title {
            text-align: left;
        }

        .header__welcome {
            margin-top: 30px;
        }

        .header__menu {
            top: 0;
            bottom: 5rem;
            transform: translateY(0);
        }

        &::before {
						right: -47vw;
						bottom: 0;
						width: 99.8rem;
						height: 100%;
						/* background-image: url(http://localhost:3001/static/media/inner-header-bg.96ade02….svg); */
						background-size: cover;
						border-radius: 40px;
            @include respond(w600) {
                right: -70rem;
            }
        }

        &::after {
            display: none;
        }
    }
}

.about {
    width: 100%;
    height: 75rem;
    display: flex;

    @include respond(w600) {
        height: 100vh;
    }

    &__container {
        display: flex;
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 50;
        align-self: flex-start;
        justify-self: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 5vh 4rem;
            flex-direction: column-reverse;
        }
    }

    &__team-pic {
        max-width: 60rem;
        flex-grow: 1;
        flex-basis: 1rem;

        @include respond(w600) {
            width: 100%;
						margin-top: 30px;
        }
    }

    &__info {
        flex-grow: 1;
        flex-basis: 1rem;
        margin-left: 10rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include respond(w600) {
            width: 100%;
            margin-left: 0;
        }

        &--title {
            font-weight: 500;
            font-size: 14px;
            color: #14C4EB;
            line-height: 2.2rem;
            margin-top: 3.6rem;
            margin-bottom: 1.6rem;
        }

        &--subtitle {
            font-weight: 700;
            font-size: 5.4rem;
            color: #FFFFFF;
            line-height: 5.8rem;
            margin-top: 0;
            margin-bottom: 1.6rem;
        }

        &--desc {
            font-family: 'Jura', sans-serif;
            font-weight: 500;
            font-size: 15px;
            color: #FFFFFF;
            line-height: 22px;
            margin-top: 0;
            margin-bottom: 2.4rem;
        }
    }
}

.features {
    width: 100%;
    height: 75rem;
    display: flex;
    position: relative;
    background-color: #131313;

    @include respond(w600) {
        height: auto;
        padding-bottom: 45vh;
    }

    &__container {
        display: flex;
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 50;
        align-self: flex-start;
        justify-self: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            flex-direction: column;
            margin: 0;
            padding: 8vh 4rem;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: auto;
        left: 0;
        width: 38.5rem;
        height: 53.5rem;
        background-color: #EAC44B;
        border-radius: 0 2.2rem 2.2rem 0;
        z-index: 10;

        @include respond(w600) {
            bottom: 0;
            transform: translateY(0);
						//width: 54vw;
						//height: 38vh;
						width: 58vw;
						height: 45vh;
        }
    }

    &.dark {
        background-color: #0F0F0F;
				&.tokenb{
					&::before {
						@include respond(w600) {
							top: 71vh;
							display: none;
						}
					}
				}

    }

    &.right {
        .features__container {
            flex-direction: row-reverse;

            @include respond(w600) {
                flex-direction: column;
            }
        }

        .features__info {
            margin-right: 5.8rem;
        }

        &::before {
            left: auto;
            right: 0;
            border-radius: 2.2rem 0 0 2.2rem;
        }

				&.tokenb{
					&::before {
						@include respond(w600) {
							top: 72vh;
							display: none;
						}

					}
				}
				&.tokenc{
					&::before {
						@include respond(w600) {
							top: 38vh; //sembrava impossibile ma...
							display: none;
						}
					}
				}

        @include respond(w600) {
            margin-top: 0;
        }
    }

    .features__dao-pic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4.5rem;
        width: 65rem;
        z-index: 20;

        @include respond(w600) {
					//bottom: 0vh;
					//transform: translateY(0) scale(1.2);
					//width: 80vw;
					//right: 5vw;
					//top: 53%;

					bottom: 0vh;
					/* transform: translateY(0) scale(1.2); */
					height: 45vh;
					right: 5vw;
					width: auto;
					top: 70vh;
				}
    }

    .features__token-pic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19rem;
        width: 43rem;
        height: 43rem;
        border-radius: 2rem;
        z-index: 20;

        @include respond(w600) {
					bottom: 0;
					transform: translateY(0);
					left: 0;
					width: 90%;
					height: auto;
					margin: 0 5%;
				}
    }

    .features__first-collection-pic	{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 9.8rem;
        width: 52rem;
        height: 49.2rem;
        z-index: 20;

        @include respond(w600) {
						bottom: 1vh;
						transform: translateY(0);
						left: 0;
						width: 90%;
						height: auto;
						margin: 0 5%;
		
        }
    }

    .features__mundial-pic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14rem;
        width: 49.6rem;
        height: 47rem;
        z-index: 20;

        @include respond(w600) {
					top: auto;
					bottom: 4vh;
					transform: translateY(0);
					width: 90%;
					height: auto;
					margin: 0 5%;
					left: 0;
				}

    }

    .features__your-assets-pic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5.8rem;
        height: 60vh;
        border-radius: 2rem;
        z-index: 20;

        @include respond(w600) {
            top: auto;
            transform: translateY(0);
            bottom: 5vh;
						height: 45vh;

        }
    }

    .features__live-streaming-pic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.5rem;
        //width: 66.5rem;
        height: 60vh;
        z-index: 20;

        @include respond(w600) {
            top: auto;
            transform: translateY(0);
						height: 45vh;
            bottom: -3vh;
            left: 0;
        }
    }

    .features__voting-pic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8rem;
        width: auto;
        height: 60vh;
        z-index: 20;

        @include respond(w600) {
            top: auto;
            bottom: -1vh;
            transform: translateY(0);
						height: 45vh;
						width: auto;
        }
    }

    &__bg {
        // width: 50%;
        flex-grow: 1;
        flex-basis: 1rem;
        position: relative;

        @include respond(w600) {
            height: 40rem;
        }
    }

    &__info {
        flex-grow: 1;
        flex-basis: 1rem;
        // margin-left: 10rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include respond(w600) {
            width: 100%;
            margin-left: 0;
        }

        &--title {
            font-weight: 500;
            font-size: 14px;
            color: #14C4EB;
            line-height: 2.2rem;
            margin-top: 3.6rem;
            margin-bottom: 1.6rem;
        }

        &--subtitle {
            font-weight: 700;
            font-size: 5.4rem;
            color: #FFFFFF;
            line-height: 5.8rem;
            margin-top: 0;
            margin-bottom: 1.6rem;
						@include respond(w600) {
							font-weight: 700;
							font-size: 30px;
							color: #FFFFFF;
							line-height: 30px;
							margin-top: 0;
							margin-bottom: 10px;
						}
	
        }

        &--desc {
            font-family: 'Jura', sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 2.4rem;
						@include respond(w600) {
							margin-bottom: 18px;
							font-size: 14px;

						}

        }
    }
}

.schermata-pic-cont {
    .schermata-pic {
        width: 100%;

        @include respond(w600) {
            width: auto;
            height: 40rem;
        }
    }

    @include respond(w600) {
        margin-top: 8rem;
        height: 40rem;
        overflow-y: hidden;
        overflow-x: auto;
    }
}

.governance {
    width: 100%;
    position: relative;
    padding: 6rem 0;

    &__container {
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 0 4rem;
            flex-direction: column;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 5.4rem;
        color: #FFFFFF;
        line-height: 5.8rem;
        margin-top: 0;
        margin-bottom: 8rem;
        text-align: center;
    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;

        &::before {
            content: '';
            width: .5rem;
            background-color: #FFFFFF;
            border-radius: .1rem;
            position: absolute;
            top: 0;
            bottom: 6.4rem;
            left: calc(50% - .25rem);
            transform: translateX(-50%);

            @include respond(w600) {
                right: 0;
                left: auto;
                transform: translateX(0);
            }
        }

        &--item {
            display: flex;
            flex-direction: row;
            position: relative;

            @include respond(w600) {
                flex-direction: column;
            }

            &::before {
                content: '';
                position: absolute;
                top: 2rem;
                left: calc(50% - 1.1rem);
                width: 1.6rem;
                height: 1.6rem;
                border-radius: .8rem;
                background-color: #FFFFFF;

                @include respond(w600) {
                    right: -0.5rem;
                    left: auto;
                }
            }

            .item-info {
                flex-grow: 1;
                flex-basis: 1rem;
                padding-right: 4.8rem;

                &--box {
                    background-color: rgba(0, 0, 0, 0.27);
                    border-radius: 2.4rem;
                    border: .1rem solid #444444;
                    padding: 3.2rem;

                    .title {
                        font-weight: 600;
                        font-size: 14px;
                        color: #EAC44B;
                        line-height: 1.9rem;
                        margin-top: 0;
                        margin-bottom: 1.8rem;
                    }

                    .subtitle {
                        font-weight: 600;
                        font-size: 2.2rem;
                        color: #FFFFFF;
                        //line-height: 2.3rem;
                        margin-top: 0;
                        margin-bottom: 1.4rem;
                    }

                    .detail {
                        font-family: 'Jura', sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 3.2rem;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }

            .item-chart {
                flex-grow: 1;
                flex-basis: 1rem;
                padding-left: 4.8rem;
                display: flex;
                align-items: flex-start;
                justify-content: center;

                @include respond(w600) {
                    padding-right: 4.8rem;
                    padding-left: 0;
                    padding-top: 4.8rem;
                }

                .chart {
										width: 36vh;
										height: 36vh;
                    border-radius: 50%;
                    // border: 3.2rem solid #000000;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: radial-gradient(closest-side, #0f0f0f 77%, transparent 0 99.9%, #000 0), conic-gradient(#eac44a 0 20%, #000 20% 100%);

										@include respond(w600) {
											width: 32vh;
											height: 32vh;
										}

                    .chart-title {
                        font-weight: 600;
                        font-size: 17px;
                        color: #FFFFFF;
                        line-height: 2.5rem;
                        margin-top: 0;
                        margin-bottom: 2rem;
                    }

                    .chart-percent {
                        font-family: 'Jura', sans-serif;
                        font-weight: 600;
                        font-size: 2.4rem;
                        color: #FFFFFF;
                        line-height: 2.8rem;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }

            &+.governance__list--item {
                margin-top: 8.5rem;
            }

            &.right {
                flex-direction: row-reverse;

                @include respond(w600) {
                    flex-direction: column;
                }

                .item-info {
                    padding-right: 0;
                    padding-left: 4.8rem;

                    @include respond(w600) {
                        padding-right: 4.8rem;
                        padding-left: 0;
                    }
                }

                .item-chart {
                    padding-right: 4.8rem;
                    padding-left: 0;
                }
            }
        }
    }
}

.team {
	width: 100%;
	position: relative;
	padding: 2rem 0 11.8rem;

	&__container {
			width: 100%;
			max-width: 128rem;
			margin: auto;
			position: relative;
			z-index: 50;

			@include respond(w1440) {}

			@include respond(w1200) {}

			@include respond(w768) {}

			@include respond(w600) {
					padding: 0 4rem;
			}
	}

	&__title {
			font-weight: 700;
			font-size: 5.4rem;
			color: #FFFFFF;
			line-height: 5.8rem;
			margin-top: 0;
			margin-bottom: 6.5rem;
			text-align: left;
	}

	&__nav {
			display: flex;
			position: absolute;
			right: 0;
			bottom: -5rem;

			@include respond(w600) {
					right: 4rem;
			}

			&--btn {
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					transition: .5s;
					cursor: pointer;

					img {
							width: 2.5rem;
							height: 2.5rem;
					}

					&:hover {
							opacity: .6;
					}

					&+.team__nav--btn {
							margin-left: 1.6rem;
					}
			}
	}

	&__list {
			display: flex;
			flex-direction: row;
			overflow: hidden;
			transition: .5s;
			scroll-behavior: smooth;

			@include respond(w600) {
					// flex-direction: column;
			}

			&--item {
					display: flex;
					flex-direction: column;
					align-items: center;
					flex-grow: 1;
					flex-basis: 1rem;
					min-width: calc(100% / 3 - 1.4rem);
					background: linear-gradient(102.17deg, rgba(0, 0, 0, 0.64) 0.47%, rgba(22, 22, 22, 0) 121.14%);
					filter: drop-shadow(0px .4rem .4rem rgba(0, 0, 0, 0.25));
					border-radius: 2.4rem;
					border: .1rem solid rgba(#EAC44B, .21);
					padding: 2.7rem 2.3rem;

					@include respond(w600) {
							min-width: 100%;
					}

					&+.team__list--item {
							margin-left: 2.1rem;

							@include respond(w600) {
									margin-top: 0rem;
									margin-left: 0;
							}
					}

					.team-pic {
							width: 13.2rem;
							height: 13.2rem;
							border-radius: 7rem;
							object-fit: cover;
							margin-bottom: 2.4rem;
					}

					.name {
							font-weight: 600;
							font-size: 14px;
							color: #FFFFFF;
							margin-top: 0;
							margin-bottom: 8px;
							text-align: center;
							@include respond(w600) {
								font-size: 16px;
								line-height: 20px;
								margin-bottom: 6px;
						}

					}

					.position {
							font-weight: 500;
							font-size: 9px;
							color: #EAC44B;
							//line-height: 1.5rem;
							margin-top: 0;
							margin-bottom: 8px;
							text-align: center;
							@include respond(w600) {
								font-size: 12px;
								line-height: 10px;
								margin-bottom: 8px;
						}

					}

					.desc {
							font-family: 'Jura', sans-serif;
							font-weight: 500;
							font-size: 12px;
							color: #FFFFFF;
							line-height: 18px;
							margin-top: 0;
							margin-bottom: 10px;
							text-align: center;
							flex-grow: 1;
							@include respond(w600) {
								font-size: 14px;
								line-height: 20px;
								margin-bottom: 15px;
							}

					}

					.yellow-outline-btn {
							width: 100%;
							@include respond(w600) {
								font-size: 14px;
								height: 50px;
							}

					}
			}
	}
}


// ROADMAP
.roadmap {
	width: 100%;
	position: relative;
	padding: 2rem 0 11.8rem;

	&__container {
			width: 100%;
			max-width: 128rem;
			margin: auto;
			position: relative;
			z-index: 50;

			@include respond(w1440) {}

			@include respond(w1200) {}

			@include respond(w768) {}

			@include respond(w600) {
					padding: 0;
			}
	}

	&__title {
			font-weight: 700;
			font-size: 5.4rem;
			color: #FFFFFF;
			line-height: 5.8rem;
			margin-top: 0;
			margin-bottom: 6.5rem;
			text-align: left;
			@include respond(w600) {
				padding: 0 4rem;
			}


			
	}

	&__nav {
			display: flex;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 999;

			@include respond(w600) {
					right: 4rem;
					display: none;
			}

			&--btn {
					background: none;
					border: none;
					padding: 0;
					margin: 10px;
					transition: .5s;
					cursor: pointer;

					img {
							width: 35px;
							height: 35px;
							@include respond(w600) {
								width: 25px;
								height: 25px;
							}
					}

					&:hover {
							opacity: .6;
					}

					&+.team__nav--btn {
							margin-left: 1.6rem;
					}
			}
	}

	&__list {
			display: flex;
			flex-direction: row;
			overflow: hidden;
			transition: .5s;
			scroll-behavior: smooth;

			@include respond(w600) {

					// flex-direction: column;
			}

			&--item {
					display: flex;
					flex-direction: column;
					align-items: center;
					flex-grow: 1;
					flex-basis: 1rem;
					min-width: calc(100% / 3 - 1.4rem);
					//background: linear-gradient(102.17deg, rgba(0, 0, 0, 0.64) 0.47%, rgba(22, 22, 22, 0) 121.14%);
					//filter: drop-shadow(0px .4rem .4rem rgba(0, 0, 0, 0.25));
					//border-radius: 2.4rem;
					//border: .1rem solid rgba(#EAC44B, .21);
					//padding: 2.7rem 2.3rem;

					@include respond(w600) {
							min-width: 100%;
							overflow-y: auto;
					}

					&+.team__list--item {
							margin-left: 2.1rem;

							@include respond(w600) {
									margin-top: 0rem;
									margin-left: 0;
							}
					}

					.roadmap-pic {
						height: 64vh;
						left: 28vw;
						position: relative;
						@include respond(w1440) {
							left: 16vw;
						}
						@include respond(w1200) {
							left: 14vw;
						}
						@include respond(w600) {
							height: 48vh; //45
							position: relative;
							left: 87vw;
						}
					}

					.name {
							font-weight: 600;
							font-size: 2.4rem;
							color: #FFFFFF;
							line-height: 2.5rem;
							margin-top: 0;
							margin-bottom: 1.2rem;
							text-align: center;
							@include respond(w600) {
								font-size: 16px;
								line-height: 20px;
								margin-bottom: 6px;
						}

					}

					.position {
							font-weight: 500;
							font-size: 1.4rem;
							color: #EAC44B;
							line-height: 1.5rem;
							margin-top: 0;
							margin-bottom: 1.2rem;
							text-align: center;
							@include respond(w600) {
								font-size: 12px;
								line-height: 10px;
								margin-bottom: 8px;
						}

					}

					.desc {
							font-family: 'Jura', sans-serif;
							font-weight: 500;
							font-size: 1.4rem;
							color: #FFFFFF;
							line-height: 2.7rem;
							margin-top: 0;
							margin-bottom: 2.4rem;
							text-align: center;
							flex-grow: 1;
							@include respond(w600) {
								font-size: 14px;
								line-height: 20px;
								margin-bottom: 15px;
							}

					}

					.yellow-outline-btn {
							width: 100%;
							@include respond(w600) {
								font-size: 14px;
								height: 50px;

							}

					}
			}
	}
}

.main-footer {
    background-color: #131313;
    width: 100%;
    position: relative;
    padding: 3.4rem 0;

    &__container {
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 0 4rem;
        }
    }

    &__logo {
        width: 20rem;
        align-self: center;
    }

    &__links {
        display: flex;
        padding-top: 9rem;
        padding-bottom: 3.2rem;
        border-bottom: .1rem solid #FFFFFF;

        @include respond(w600) {
            flex-direction: column;
        }

        &--item {
            flex-grow: 1;

            .title {
                font-size: 2.4rem;
                line-height: 20px;
                font-weight: 600;
                color: #FFFFFF;
                margin-bottom: 1.6rem;
            }

            .detail {
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 2.4rem;
                width: 46rem;
            }

            .check-item {
                font-size: 14px;
                line-height: 2.2rem;
                font-weight: 600;
                color: #FFFFFF;
                margin-top: 0;
                margin-bottom: 2.4rem;
                padding-left: 3.4rem;
                position: relative;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2rem;
                    height: 2rem;
                    background-image: url(./../../public/icons/check-ico.svg);
                    background-repeat: no-repeat;
                    background-size: 2rem 2rem;
                }
            }

            .email {
                font-size: 14px;
                line-height: 3.5rem;
                font-weight: 400;
                color: #FFFFFF;
                padding: 0;
                margin: 0;
                background: none;
                border: none;
                margin-bottom: 2.4rem;
                padding-left: 3.6rem;
                position: relative;
                text-decoration: none;
                transition: .5s;

                &:hover {
                    opacity: .8;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: .5rem;
                    left: 0;
                    width: 2.1rem;
                    height: 1.5rem;
                    background-image: url(./../../public/icons/email-ico.svg);
                    background-repeat: no-repeat;
                    background-size: 2.1rem 1.5rem;
                }
            }

            .documents {
                display: grid;
                grid-template-columns: repeat(2, auto);
                row-gap: 2.4rem;
                column-gap: 2.4rem;
                padding-top: .8rem;
            }

            .pdf {
                font-size: 14px;
                line-height: 2.2rem;
                font-weight: 400;
                color: #FFFFFF;
                padding: 0;
                margin: 0;
                background: none;
                border: none;
                padding-left: 3.6rem;
                position: relative;
                text-decoration: none;
                transition: .5s;

                &:hover {
                    opacity: .8;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: -.2rem;
                    left: 0;
                    width: 1.8rem;
                    height: 2.4rem;
                    background-image: url(./../../public/icons/pdf-ico.svg);
                    background-repeat: no-repeat;
                    background-size: 1.8rem 2.4rem;
                }
            }

            &+.main-footer__links--item {
                @include respond(w600) {
                    margin-top: 6rem;
                }
            }
        }
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.4rem;

        &__icon-btn {
            background: none;
            border: none;
            padding: 0;
            margin: 0;
            outline: none;
            transition: .5s;
            cursor: pointer;

            img {
                width: 2.55rem;
                height: auto;
            }

            &:hover {
                opacity: .8;
            }

            &+.main-footer__social__icon-btn {
                margin-left: 1.8rem;
            }
        }
    }
}

.company {
    width: 100%;
    height: 87rem;
    display: flex;

    &.gray {
        background-color: #131313;
    }

    @include respond(w600) {
        height: auto;
        padding: 8rem 0;
    }

    &__container {
        display: flex;
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 50;
        align-self: flex-start;
        justify-self: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 0 4rem;
            flex-direction: column-reverse;
        }
    }

    &__pic {
        flex-grow: 1;
        flex-basis: 1rem;
        display: flex;

        @include respond(w600) {
            width: 100%;
        }

        .how-it-work-pic {
            width: 100%;
            max-width: 52rem;
	
        }

        .token-pic {
            width: 100%;
            max-width: 52rem;
						@include respond(w600) {
							max-width: 90%;
							display: block;
							margin: 35px 5% 0;
						}

        }
    }

    &__info {
        flex-grow: 1;
        flex-basis: 1rem;
        margin-left: 10rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include respond(w600) {
            width: 100%;
            margin-left: 0;
        }

        &--title {
            font-weight: 500;
            font-size: 14px;
            color: #14C4EB;
            line-height: 2.2rem;
            margin-top: 3.6rem;
            margin-bottom: 1.6rem;
        }

        &--subtitle {
            font-weight: 700;
            font-size: 5.4rem;
            color: #FFFFFF;
            line-height: 5.8rem;
            margin-top: 0;
            margin-bottom: 1.6rem;
        }

        &--desc {
            font-family: 'Jura', sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 2.4rem;
        }
    }
}

.the-company {
    width: 100%;
    height: 74.5rem;
    display: flex;
    background-color: #131313;

    @include respond(w600) {
        height: auto;
    }

    &__container {
        display: flex;
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 50;
        align-self: flex-start;
        justify-self: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 8rem 4rem;
            flex-direction: column-reverse;
        }
    }

    &__chart {
        flex-grow: 1;
        flex-basis: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond(w600) {
            width: 100%;
        }

        .chart {
						width: 36vh;
						height: 36vh;
						border-radius: 50%;
            // border: 3.2rem solid #000000;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: radial-gradient(closest-side, #0f0f0f 83%, transparent 0 99.9%, #000 0), conic-gradient(#eac44a 0 49%, #fff 49% 80%, #000 80% 100%);

            @include respond(w600) {
                margin-bottom: 4rem;
            }

            .chart-title {
                font-weight: 600;
                font-size: 4.4rem;
                color: #FFFFFF;
                line-height: 4.6rem;
                margin-top: 0;
            }

            .chart-percent {
                font-family: 'Jura', sans-serif;
                font-weight: 600;
                font-size: 2.4rem;
                color: #FFFFFF;
                line-height: 2.8rem;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    &__info {
        flex-grow: 1;
        flex-basis: 1rem;
        margin-right: 10rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include respond(w600) {
            width: 100%;
            margin-left: 0;
        }

        &--title {
            font-weight: 500;
            font-size: 14px;
            color: #14C4EB;
            line-height: 2.2rem;
            margin-top: 3.6rem;
            margin-bottom: 1.6rem;
        }

        &--subtitle {
            font-weight: 700;
            font-size: 5.4rem;
            color: #FFFFFF;
            line-height: 5.8rem;
            margin-top: 0;
            margin-bottom: 1.6rem;
        }

        &--desc {
            font-family: 'Jura', sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 2.4rem;
        }
    }
}

.video {
	width: 100%;
	padding: 8rem 6.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	@include respond(w600) {
			padding: 4rem;
			@include respond(w600) {
				padding: 20px;
			}

	}

	&__bg {
			width: 100%;
			border-radius: 20px;

			@include respond(w600) {
					height: 30vh;
					object-fit: cover;
			}
	}

	&__content {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 20;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
	}

	&__title {
			font-weight: 700;
			font-size: 5.4rem;
			color: #FFFFFF;
			line-height: 5.8rem;
			margin-top: 0;
			margin-bottom: 5px;

			@include respond(w600) {
				font-size: 30px;
				line-height: 35px;
				margin-top: 0;
				margin-bottom: 5px;
		}
	

	}

	&__desc {
			font-family: 'Jura', sans-serif;
			font-weight: 500;
			font-size: 1.8rem;
			color: #FFFFFF;
			line-height: 3rem;
			margin-top: 0;
			margin-bottom: 5.4rem;
			@include respond(w600) {
				font-size: 14px;
				margin-bottom: 18px;
				line-height: 15px;


		}


	}

	&__btn {
			background: none;
			border: none;
			padding: 0;
			margin: 0;
			cursor: pointer;
			transition: .5s;

			&:hover {
					opacity: .6;
			}

			img {
				width: 11.2rem;
				@include respond(w600) {
					width: 65px;
				}
		
			}
	}
}

.swot-analysis {
    width: 100%;
    padding: 7rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__container {
        width: 100%;
        max-width: 128rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 7rem 4rem;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 5.4rem;
        color: #FFFFFF;
        line-height: 5.8rem;
        margin-top: 0;
        margin-bottom: 7rem;
        text-align: center;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 4.7rem;
        column-gap: 4.7rem;

        @include respond(w600) {
            grid-template-columns: repeat(1, 1fr);
        }

        &--item {
            background: linear-gradient(102.17deg, #131313 0.47%, #131313 121.14%);
            box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.25);
            border-radius: 2.4rem;
            border: .1rem solid rgba(#E9E9E9, .05);
            padding: 3.6rem 3.2rem;

            .title {
                font-weight: 600;
                font-size: 14px;
                color: #EAC44B;
                line-height: 1.9rem;
                margin-top: 0;
                margin-bottom: 2.2rem;
            }

            .desc {
                font-family: 'Jura', sans-serif;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                //line-height: 2.2rem;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

.check-ranking {
    background-color: #0F0F0F;
    width: 100%;
    height: 94.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        background-color: #EAC44B;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 45rem;
        border-radius: 0 2.2rem 2.2rem 0;

        @include respond(w600) {
            top: 8rem;
            left: 0;
            bottom: 8rem;
            width: 20vw;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: -36rem;
        top: 50%;
        transform: translateY(-50%);
        width: 65.5rem;
        height: 75.3rem;
        z-index: 15;
        background-image: url(./../../public/images/header-bg-2.png);
        background-repeat: no-repeat;
        background-size: 65.5rem 75.3rem;
        opacity: .36;

        @include respond(w600) {
            transform: translateY(-50%) scale(.8);
						display: none;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 5.4rem;
        color: #FFFFFF;
        line-height: 5.8rem;
        text-align: center;
        margin-top: 0;
        margin-bottom: 1.6rem;
        position: relative;
        z-index: 20;
    }

    &__desc {
        font-family: 'Jura', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        z-index: 20;
    }

    &__pic {
        width: 100%;
        max-width: 100rem;
        position: relative;
        z-index: 20;
        margin-top: 5.5rem;
        margin-bottom: 5.5rem;
    }

    &__btn {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 25;
        transform: translateX(-50%) translateY(-50%);
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: .5s;

        &:hover {
            opacity: .6;
        }

        img {
            width: 11.2rem;
						@include respond(w600) {
							width: 65px;
						}
			
        }
    }
}

.vip-collection {
    background-color: #131313;
    width: 100%;
    height: 75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    padding-left: 12.4rem;

    @include respond(w600) {
        height: auto;
        padding-top: 8rem;
        padding-bottom: 60rem;
        padding-left: 8rem;
    }

    &::before {
        content: '';
        //background-color: #EAC44B;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 5.7rem;
        height: 53.5rem;
        z-index: 10;
        border-radius: 0 2.2rem 2.2rem 0;

        @include respond(w600) {
            top: auto;
            transform: translateY(0);
            bottom: 0;
        }
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 56rem;
        height: 64rem;
        z-index: 10;
        background-image: url(./../../public/images/HomeCard.png);
        background-repeat: no-repeat;
        background-size: 56rem 64rem;

        @include respond(w600) {
            top: auto;
            transform: translateY(0);
            bottom: 0;
            width: 49rem;
            height: 53.5rem;
            background-size: 49rem 53.5rem;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 5.4rem;
        color: #FFFFFF;
        line-height: 5.8rem;
        margin-top: 0;
        margin-bottom: 1.6rem;
        position: relative;
        z-index: 20;
    }

    &__desc {
        font-family: 'Jura', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: 3.2rem;
        position: relative;
        z-index: 20;
    }
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	/* 16:9 */
	padding-top: 25px;
	height: 0;
}

.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.btn-close{
	position: absolute;
	top: 35px;
	right: 30px;
	background: #000;
	border: 0;
	color: white;
	font-size: 19px;
	border-radius: 100px;
	width: 28px;
	height: 28px;
	cursor: pointer;
	@include respond(w600) {
		font-size: 19px;
		border-radius: 100px;
		width: 28px;
		height: 28px;
	}

}